import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as FaIcons from "react-icons/fa";
import React, { useState } from "react";
import { useBackendStatusContext } from "../context/backendStatus/backendStatusContext";
import { SET_SAVED_TARGET_VERSION, SET_UPDATE_REQUESTED } from "../context/backendStatus/backendStatusActions";
import { updateBackend } from "../actions/backendVersionActions";
import { useTheme } from '@mui/material';
import { useTranslation } from "../context/TranslationContext";
import ConfirmationDialog from "./ConfirmationModals/ConfirmationDialog";

const UpdateButton = ({ forceShow = false, selectedVersion }) => {
	const backendStatus = useBackendStatusContext();
	const theme = useTheme(); 
	const {translate} = useTranslation();	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleOpenDialog = () => {
		setIsDialogOpen(true);
	};

	const handleClose = () => {
		setIsDialogOpen(false);
	};

	const handleUpdate = async () => {
		setIsDialogOpen(false);
		//avoiding re-entrancy when the button is clicked multiple times or when the backend is already updating
		if(backendStatus.state.isUpdating){
			return;
		}
		backendStatus.dispatch({ type: SET_UPDATE_REQUESTED });

		// Use targetVersion from state if selectedVersion is null
		const versionToUpdate = selectedVersion || backendStatus.state.targetVersion;
		backendStatus.dispatch({ type: SET_SAVED_TARGET_VERSION });
		await updateBackend(versionToUpdate);
	};
	return (
		(forceShow || backendStatus.state.isUpdatePossible) && (
			<Box mr={1} sx={{ display: "flex", alignItems: "center", color: theme.palette.primary.main, flexWrap:"nowrap" }}>
				<Typography>{translate("settings.updateAvailable")}</Typography>
				<LoadingButton
					className="float-sm-end m-1"
					variant="outlined"
					startIcon={<FaIcons.FaDownload />}
					onClick={handleOpenDialog}
					loading={backendStatus.state.isUpdateRequested}
					size={"small"}
				>
					{translate("settings.install")} {backendStatus.state.targetVersion}
				</LoadingButton>
				<ConfirmationDialog
					title="Confirm Update"
					subtitle="Are you sure you want to update the device?"
					isOpen={isDialogOpen}
					onClose={handleClose}
					onConfirm={handleUpdate}
				/>
			</Box>
		)
	);
};

export default UpdateButton;
