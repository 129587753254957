import React, { useState, useContext, useEffect } from "react";
import { MdClearAll } from "react-icons/md";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
	Avatar,
	Switch,
	FormControlLabel,
	Popover,
	Toolbar,
	AppBar,
	Typography,
	CircularProgress,
	Box,
	Button
} from "@mui/material";
import { UserCard } from "../../components/Card";
import ConfirmationDialog from "../ConfirmationModals/ConfirmationDialog";

import bn from "../../utils/bemnames";
import useCognito from "../../hooks/useCognito";
import AuthContext from "../../context/authContext";
import UrlChecker from "../HealthCheck/UrlChecker";
import UpdateButton from "../UpdateButton";
import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import { useAppContext } from "../../context/appContext";
import { useError } from "../../context/errorContext";
import DarkModeToggle from "./ToggleButton";
import { useTheme } from "@mui/material";
import useStyles from "../../constants/commonStyles";
import { StyledIconButton } from "../../constants/styledComponents";
import { formatName } from "../../utils/formatName";
import { useTranslation } from '../../context/TranslationContext';
import LanguageSelector from "../LanguageSelector";
import { useSidebar } from "../../context/SidebarContext";
const bem = bn.create("header");

const Header = () => {
	const { translate } = useTranslation();
	const { signOut } = useCognito();
	const { userData } = useContext(AuthContext);
	const { toggleCompatibility, isLocalHost, toggleMockTest, togglePhoneBypass, isPhoneBypassed, isTestMocked } = useAppContext();
	const {	toggleSidebar } = useSidebar();
	const backendStatus = useBackendStatusContext()
	const savedCompatibility = localStorage.getItem("compatibility")
	const [isRebootDialogOpen, setIsRebootDialogOpen] = useState(false);
	const [isShutdownDialogOpen, setIsShutdownDialogOpen] = useState(false);
	const [loadingReboot, setLoadingReboot] = useState(false);
	const [loadingShutdown, setLoadingShutdown] = useState(false);
	const [isModalClosing, setIsModalClosing] = useState(false);

	const { handleError } = useError();
	const theme = useTheme();
	const classes = useStyles(theme);

	const [state, setState] = useState({
		isOpenNotificationPopover: false,
		isNotificationConfirmed: false,
		isOpenUserCardPopover: false,
		currentUser: formatName(userData.firstName, userData.lastName),
	});

	const toggleNotificationPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenNotificationPopover: !prevState.isOpenNotificationPopover,
			};
		});

		if (state.isNotificationConfirmed) {
			setState((prevState) => {
				return {
					...prevState,
					isNotificationConfirmed: true,
				};
			});
		}
	};

	const csrfToken = localStorage.getItem("csrfToken");

	const handleReboot = async () => {
		try {
			setLoadingReboot(true);
			await fetch(
				`${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/reboot`,
				{
					method: "GET",
					withCredentials: true,
					credentials: "include",
					headers: {
						"X-CSRF-Token": csrfToken,
					},
				}
			);

			console.log("Reboot request sent successfully");
		} catch (error) {
			console.error("Error sending reboot request", error);
			handleError(error);
		} finally {
			setIsModalClosing(true);
			// signOut();
		}
	};

	const handleShutdown = async () => {
		try {
			setLoadingShutdown(true);
			await fetch(
				`${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/shutdown`,
				{
					method: "GET",
					withCredentials: true,
					credentials: "include",
					headers: {
						"X-CSRF-Token": csrfToken,
					},
				}
			);

			console.log("Shutdown request sent successfully");
		} catch (error) {
			console.error("Error sending shutdown request", error);
			handleError(error);
		} finally {
			setIsModalClosing(true);
			signOut();
		}
	};

	const toggleUserCardPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenUserCardPopover: !prevState.isOpenUserCardPopover,
			};
		});
	};

	const handleSidebarControlButton = (event) => {
		event.preventDefault();
		event.stopPropagation();
		toggleSidebar();
	};

	const handleSwitchChange = (event) => {
		const isChecked = event.target.checked;
		toggleCompatibility(isChecked);
		location.reload();
	};

	const handleIsTestMockedSwitchToggle = (event) => {
		const isChecked = event.target.checked;
		toggleMockTest(isChecked);
	};

	const handleIsPhoneBypassedSwitchToggle = (event) => {
		const isChecked = event.target.checked;
		togglePhoneBypass(isChecked)

	}

	useEffect(() => {
    if (isModalClosing) {
        setIsRebootDialogOpen(false);
        setIsShutdownDialogOpen(false);
		setLoadingReboot(false);
		setLoadingShutdown(false);
		setIsModalClosing(false);

    }
}, [isModalClosing]);

	return (
		<>
			<AppBar position="static" className={bem.b()}>
				<Toolbar className="d-flex justify-content-between">
					<div style={{ display: "flex" }}>
						<StyledIconButton onClick={handleSidebarControlButton}>
							<MdClearAll size={25} />
						</StyledIconButton>
						<DarkModeToggle />
					</div>

					<div style={{ display: "flex", alignItems: "center" }}>
						 <LanguageSelector />
						<div style={{ width: "1px", height: "24px", backgroundColor: "gray", marginRight: "10px" }}></div>
						<div className="d-flex align-items-center">
							{backendStatus.state.isUpdatePossible && <UpdateButton />}
							<UrlChecker
								url={`${import.meta.env.VITE_APP_SERVER_URI}/readiness`}
								reverse={true}
								circleSize={40}
								upAlias={translate("settings.deviceStatus.ready")}
								downAlias={translate("settings.deviceStatus.notReady")}
							/>
							<Avatar onClick={toggleUserCardPopover} className={classes.cursor} />
							<div>
								<Popover
									open={state.isOpenUserCardPopover}
									onClose={toggleUserCardPopover}
									anchorEl={document.getElementById("Popover2")}
									anchorOrigin={{ vertical: "top", horizontal: "right" }}
									transformOrigin={{ vertical: "top", horizontal: "right" }}
									PaperProps={{ style: { minWidth: 250, marginTop: "40px" } }}
								>
									<UserCard
										title={
											state.currentUser ? (
												state.currentUser
											) : (
												<span style={{ color: theme.palette.error.main }}>
													{translate('header.nameIsMissing')}
												</span>
											)
										}
										onSignOut={signOut}
										// text="Last updated 3 mins ago"
										text=""
									>
										<div
											style={{
												display: "flex",
												padding: "14px",
												gap: 5,
											}}
										>
											<Button
												startIcon={<RotateLeftIcon style={{ stroke: theme.palette.primary.main }} />}
												onClick={() => setIsRebootDialogOpen(true)}
												variant="outlined"
												style={{ fontSize: 14 }}
											>
												{translate('header.RebootDevice')}
											</Button>
                                            
                                            
											<Button
												startIcon={<PowerSettingsNewIcon style={{ stroke: theme.palette.primary.main }} />}
												onClick={() => setIsShutdownDialogOpen(true)}
												variant="outlined"
												style={{ fontSize: 14 }}   
											>
												{translate('header.SwitchOffDevice')}
											</Button>
										</div>
									</UserCard>

									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										{isLocalHost && (
											<div>
												<FormControlLabel
													control={
														<Switch
															onChange={handleSwitchChange}
															defaultChecked={JSON.parse(savedCompatibility)}
														/>
													}
													label={translate('header.BypassCompatibility')}
												/>

											</div>
										)}
										{isLocalHost && (
											<div>
												<FormControlLabel
													control={
														<Switch onChange={handleIsTestMockedSwitchToggle} defaultChecked={JSON.parse(isTestMocked)} />
													}
													label={translate('header.MockDataAcquisition')}
												/>
											</div>
										)}

										{isLocalHost && (
											<div>
												<FormControlLabel
													control={
														<Switch onChange={handleIsPhoneBypassedSwitchToggle} defaultChecked={JSON.parse(isPhoneBypassed)} />
													}
													label={translate('header.MockPhoneDetection')}
												/>
											</div>
										)}
									</div>
								</Popover>
							</div>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			{isRebootDialogOpen && (<ConfirmationDialog
				isOpen={isRebootDialogOpen}
				onClose={() => {
					setIsRebootDialogOpen(false);
					setIsModalClosing(true);
				}}
				onConfirm={handleReboot}
				title={
					loadingReboot ? (
						<Box display="flex" justifyContent="center" alignItems="center">
							<Typography style={{ marginRight: '20px', fontSize: '22px' }}>
							{translate('header.RebootInProgress')}
							</Typography>
							<CircularProgress color="primary" />
						</Box>
					) : (
						translate('header.RebootDevice')
					)
				}
				subtitle={translate('header.AreYouSureReboot')}
			/>

			)}
			<ConfirmationDialog
				isOpen={isShutdownDialogOpen}
				onClose={() => {
					setIsRebootDialogOpen(false);
					setIsModalClosing(true);
				}}
				onConfirm={handleShutdown}
				title={loadingShutdown ? (
					<Box display="flex" justifyContent="center" alignItems="center">
						<Typography style={{ fontSize: '20px' }}>
						{translate('header.SwitchOffInProgress')}
						</Typography>
					</Box>
				) : (
					translate('header.SwitchOffDevice')
				)}
				subtitle={loadingShutdown ? "" : translate('header.AreYouSureSwitchOff')}
				inProgress={loadingShutdown}
			/>
			
		</>
	);
};

export default Header;
