import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "../../context/TranslationContext";
import { StyledCloseIcon } from "../../constants/styledComponents";

const DialogBase = ({
    title,
    subtitle,
    isOpen,
    onClose,
    onConfirm,
    icon,
    bgColor,
    confirmLabel,
    confirmButtonColor = "primary",
    confirmIcon,
    inProgress = false,
    dialogAbortId,
}) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    return (
        <Dialog
            fullWidth
            open={isOpen}
            onClose={(_, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") onClose();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEnforceFocus
            PaperProps={{
                sx: {
                    overflow: "visible",
                    borderRadius: "14px",
                    maxWidth: "520px",
                    width: "100%",
                },
            }}
        >
            <StyledCloseIcon
                onClick={onClose}
                sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    cursor: "pointer",
                }}
            />
            <Box display="flex" justifyContent="center" marginTop="-35px">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor={bgColor}
                    color={theme.palette.background.common}
                    borderRadius="50%"
                    width="100px"
                    height="100px"
                    border="10px solid"
                    borderColor={theme.palette.background.common}
                >
                    {inProgress ? (
                        <CircularProgress color="inherit" size={40} />
                    ) : (
                        icon
                    )}
                </Box>
            </Box>
            <Typography variant="h5" align="center" style={{ marginBottom: "5px", paddingTop: 10, paddingBottom: 8 }}>
                {title}
            </Typography>
            <DialogContentText id="alert-dialog-description">
                <Typography variant="body1" align="center" marginBottom={4}>
                    {subtitle}
                </Typography>
            </DialogContentText>
            <DialogActions>
                {!inProgress && (
                    <Button variant="outlined" onClick={onClose} startIcon={<CancelIcon />}>
                        {translate("cancel")}
                    </Button>
                )}
                {!inProgress && (
                    <Button
                        variant="contained"
                        onClick={(e) => onConfirm(e)}
                        color={confirmButtonColor}
                        startIcon={confirmIcon}
                        id={dialogAbortId}
                    >
                        {confirmLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};;

export default DialogBase;
