import React from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-undef
const sourceUrl = import.meta.env.VITE_APP_SOURCE_URL;

const SourceLink = props => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    event.preventDefault();
    navigate('/');
};
  return (
    <a href={sourceUrl} onClick={handleClick} rel="noopener noreferrer" {...props} />
  );
};

export default SourceLink;
