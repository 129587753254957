import React, {useState, useEffect} from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../context/TranslationContext';


const NotFoundPage = () => {
    const [eyePosition, setEyePosition] = useState({ leftEye: { x: 0, y: 0 }, rightEye: { x: 0, y: 0 } }); 
    const navigate = useNavigate();
    const {translate} = useTranslation();
    
    const handleGoHome = () => {
        navigate('/');
    };

    const handleMouseMove = (event) => {

        const {clientX, clientY} = event;

        const calculatePupilPosition = (centerX, centerY, mouseX, mouseY) => {

            const distanceX = centerX - mouseX;
            const distanceY = centerY - mouseY;

            const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

            const maxDistance = 8;
            const angle = Math.atan2(distanceY, distanceX); 
            const limitedX = Math.cos(angle) * Math.min(distance, maxDistance); 
            const limitedY = Math.sin(angle) * Math.min(distance, maxDistance);

            return {x: limitedX, y: limitedY}

        }; 

        // Position of eyes container
        const leftEye = document.getElementById('leftEye').getBoundingClientRect();
        const rightEye = document.getElementById('rightEye').getBoundingClientRect();

        const newLeftEyePosition = calculatePupilPosition(leftEye.left + leftEye.width / 2, leftEye.top + leftEye.height / 2, clientX, clientY); 
        const newRightEyePosition = calculatePupilPosition(rightEye.left + rightEye.width / 2, rightEye.top + rightEye.height / 2, clientX, clientY);

        setEyePosition({leftEye: newLeftEyePosition, rightEye: newRightEyePosition});

    }; 

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        }

    }, [])

    const styles = {
        container: {
            height: '87vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            position: 'relative',
        },
        item: {
            margin: '8px 0',
        },
        button: {
            marginTop: '24px',
            fontSize: "18px"
        },
        moveArea: {
            width: '250px',
            height: '100px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        eyeContainer: {
            display: 'flex',
            width: '100%',
           justifyContent: "center"
        },
        eye: {
            position: 'relative',
            display: 'inline-block',
            borderRadius: '50%',
            height: '36px',
            width: '36px',
            background: '#FFF',
            border: '2px solid #000',
            boxShadow: 'inset 0px 0px 5px rgba(0,0,0,0.5)', 
        },
        pupil: (x, y) => ({
            position: 'absolute',
            bottom: `calc(50% - 8px + ${y}px)`,
            right: `calc(50% - 8px + ${x}px)`,
            width: '13px',
            height: '13px',
            backgroundColor: '#000',
            borderRadius: '50%',
            boxShadow: '0px 0px 4px rgba(0,0,0,0.5)',
        }),
    };

    return (
        <Grid sx={styles.container}>
            <Box sx={styles.item}>
                <Typography variant="h1">404</Typography>
            </Box>
            <Box sx={styles.item}>
                <Typography variant="h3">{translate("notFoundPage.notFound")}</Typography>
            </Box>
            <Box sx={styles.item}>
                <Typography variant="body1">{translate("notFoundPage.pageDoesNotExist")}</Typography>
            </Box>
            <Button sx={styles.button} variant="contained" color="primary" onClick={handleGoHome}>
                {translate("notFoundPage.backToHome")}
            </Button>
            <Box sx={styles.moveArea}>
                <Box sx={styles.eyeContainer}>
                    <Box id='leftEye' sx={styles.eye}>
                        <Box sx={styles.pupil(eyePosition.leftEye.x, eyePosition.leftEye.y)} >
                        </Box>
                    </Box>
                    <Box id='rightEye' sx={styles.eye}>
                        <Box sx={styles.pupil(eyePosition.rightEye.x, eyePosition.rightEye.y)}></Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};

export default NotFoundPage;
