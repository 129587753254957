import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    authContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "800px",
    },
    formGroup: {
        marginBottom: theme.spacing(3),
    },
    formControl: {
        width: "100%",
    },
    centeredText: {
		textAlign: "left",
		paddingBottom: theme.spacing(2),
		fontSize: "16px",
		paddingLeft: "10px",
		color: "#495057"
    },
    linkContainer: {
		display: "flex",
		justifyContent: "flex-end",
    },
    rightAlignedText: {
        textAlign: "right",
    },
    supportIcon: {
        color: theme.palette.primary.main,
        marginBottom: '10px'
    },

    divider: {
        width: '40%',
        height: "2px",
        backgroundColor: '#D9D9D9',
        '&.MuiDivider-root': {
            margin: '10px 0',
        },
       
    },
    contactIcons: {
        color: theme.palette.primary.main,
        marginRight: '8px'

    },
    DNSInstructionsModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 520,
        backgroundColor: theme.palette.background.common,
        boxShadow: 24,
        padding: 32,
        overflow: 'auto',
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
    },

    instructionsModalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },

    closeDNSInstructionsButton: {
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-end',
            position: 'absolute',
            top: -20,
            right: -20,
        },

    },

    instructionsButton: {
        marginTop: 32,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    authError: {
		color: theme.palette.error.main,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
    },
    logo: {
		width: 300,
    },
    submitButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
    table: {
        minWidth: 350,
    },
    usersTable: {
        minWidth: 650,
    },
    tableCell: {
        width: 100,
        "&.MuiTableCell-root": {
            padding: "6px",
        },
    },
    tableHeaderCell: {
        fontWeight: "bold",
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    oddRow: {
        backgroundColor: theme.palette.background.paper, // Very light grey for odd rows
    },
    stepper: {
        padding: theme.spacing(3),
    },
    status: {
        fontWeight: "bold",
        fontSize: "0.75rem",
        color: "white",
        borderRadius: 8,
        padding: "3px 10px",
        display: "inline-block",
    },
    userName: {
        fontWeight: "bold",
    },

    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },

    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    buttonBack: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    paper: {
        overflowY: 'unset',
    },
    paperRoot: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(4),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(4),
        },
    },
    root: {
        width: "auto",
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            // width: 600,
            marginLeft: "auto",
            marginRight: "auto",
        },
    },

    deviceLabelGrid: {
        marginLeft: "10px",
        marginTop: "10px",
        [theme.breakpoints.down('md')]: {
            overflow: "auto",
        },
    },
    footer: {
        padding: '1rem',
        textAlign: 'center',
        marginTop: 'auto'

    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'lightgray',
        color: 'gray',
    },

    title: {
        marginTop: theme.spacing(2)
    },
    userCard: {
        '&.MuiCard-root': {
        background: theme.palette.common.userCard,
        color: theme.palette.common.white,
        },
    },
    cardTitle: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    cardSubtitle: {
        fontSize: '1rem',
    },
    userCardAvatar: {
        '&.MuiAvatar-root': {
            width: "70px",
            height: "70px",
            backgroundColor: theme.palette.primary.main,
        },
    },
    center: {
        textAlign: "center",
    },
    cursor: {
        cursor: "pointer",
    },
    hoverable: {
        "&:hover": {
            cursor: `pointer`,
        },
    },
    arrow: {
        fontSize: 10,
        position: "absolute",
    },
    tableRow: {
        padding: "0 5px",
        cursor: "pointer",
        ".MuiTableRow-root.MuiTableRow-hover&:hover": {},
    },
    inner: {
        minWidth: 1050,
    },
    resultTableButton: {
        marginRight: 0,
    },
    tableRoot: {
        padding: 0,
    },
    paginationRoot: {
        flexShrink: 0,
    },
    pageNumber: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        display: 'inline-block',
        width: 25,
        height: 25,
        lineHeight: '25px',
        borderRadius: '50%',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
        },
    },
    activePageNumber: {
        margin: theme.spacing(0.5),
        display: 'inline-block',
        width: 25,
        height: 25,
        lineHeight: '25px',
        borderRadius: '50%',
        textAlign: 'center',
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },

    notesContainer: {
        position: "relative", 
    }, 


    warningNotes: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.warning.main,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.warning.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.warning.main,
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.warning.main,
            '&.Mui-focused': {
                color: theme.palette.warning.main,
            },
        },
    },

    searchInput: {
        '& .MuiOutlinedInput-root': {
            paddingRight: 0,
            width: 200
        }
    },
})); 

export default useStyles;